import { defineStore } from 'pinia';
import { MetreStatus, ParametreKeys } from '~~/types/Enums';
import { useParametresStore } from './parametres';

const emptyItem = () => ({
  libelle: '',
  status: MetreStatus.EN_COURS,
  metre: null,
  marge: parseInt(useParametresStore().paramByKey(ParametreKeys.MARGE_METRE_DEFAULT).valeur),
  construction_version: null,
});

export const useMetreModelesStore = defineStore({
  id: 'metre-modeles-store',
  state: () => {
    return {
      collection: [],
      editedItem: emptyItem(),
      currentItem: null,
      page: 1,
      pageCount: 1,
      sortField: 'createdAt',
      sortDirection: ':desc',
      query: '',
      creationPanelOpened: false,
      versionForModele: null,
      api: useApi.metreModeles,
    };
  },
  actions: {
    setMetaSortPage({ page = 1, direction = '', sortField = '' }) {
      this.page = page;
      if (direction) this.sortDirection = direction;
      if (sortField) this.sortField = sortField;
    },

    setQuery(query) {
      this.page = 1;
      this.query = query;
    },

    setEditedItem(item) {
      this.editedItem = item;
    },
    setCreationPanelOpened(value) {
      this.creationPanelOpened = value;
      if (!value) {
        this.setEditedItem(emptyItem());
      }
    },
    edit(item) {
      this.setEditedItem(metre);
      this.setCreationPanelOpened(true);
    },
    createNewItem() {
      this.setEditedItem(emptyItem());
      this.setCreationPanelOpened(true);
    },
    setCurrent(item) {
      this.currentItem = item;
    },

    setVersionForModele(item) {
      this.versionForModele = item;
    },

    async fetch() {
      const populate = {
        metre: {
          populate: {
            avp: {
              populate: {
                projet: true,
              },
            },
          },
        },
        construction_version: { populate: { modele: true } },
      };
      const response = await this.api.find(
        this.page,
        this.getFullSortField,
        this.query,
        25,
        {},
        populate,
      );
      if (!response?.data) return response;
      this.collection = response.data;
      this.page = response.meta.pagination.page;
      this.pageCount = response.meta.pagination.pageCount;
      return response;
    },

    async fetchOne(id) {
      const res = await this.api.findOne(id);
      this.setCurrent(res.data);
      return res;
    },

    async create(item) {
      return await this.api.create(item);
    },

    async update(item) {
      return await this.api.update(item);
    },

    async delete(item) {
      return await this.api.delete(item);
    },

    async refreshCurrent() {
      if (!this.currentItem) return;
      await this.fetchOne(this.currentItem.id);
    },

    reset() {
      this.currentItem = null;
    },
  },
  getters: {
    getFullSortField: (state) => (state.sortField ? state.sortField + state.sortDirection : ''),
    metre: (state) => state.currentItem?.metre,
  },
});
